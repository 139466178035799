/**
 * @generated SignedSource<<26e8e4adff9a879c84ca8af8c56daa9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type trendingTopicTopic$data = {
  readonly trendingTopicsQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly name?: string;
          readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "trendingTopicTopic";
};
export type trendingTopicTopic$key = {
  readonly " $data"?: trendingTopicTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendingTopicTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "trendingTopicsQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "trendingTopicTopic",
  "selections": [
    {
      "alias": "trendingTopicsQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "trendingTopicsQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 5
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "entityLink"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "followButtonBase"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        }
                      ],
                      "type": "Topic",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:5)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "bc30bf626d4595b40fde279bbfe59bb4";

export default node;
