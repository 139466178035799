/**
 * @generated SignedSource<<8f4aabd2aa81caeb098f6bb11dd6974c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetArticleLogoArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
      readonly image: {
        readonly style: {
          readonly url: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly urlAlias: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetArticleLogoArticle";
};
export type helpersGetArticleLogoArticle$key = {
  readonly " $data"?: helpersGetArticleLogoArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetArticleLogoArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetArticleLogoArticle"
};

(node as any).hash = "f2267e2efa7a676b8985890804b7b609";

export default node;
