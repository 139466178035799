import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { Figure, StyledImage } from "scmp-app/components/author/author-avatar/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div``;

export const VideoCommentContainer = styled.div`
  display: flex;
`;

export const VideoContentContainer = styled.div`
  display: flex;
  justify-items: stretch;
`;

export const VideoHeadline = styled.div`
  flex-grow: 1;

  font-weight: 700;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;

export const StyledVideoImageContainer = styled.div`
  position: relative;
`;

export const Duration = styled.div`
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;

  inline-size: fit-content;
  padding: 4px;

  color: #4585ff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;

  border-radius: 0 4px 0 0;

  background-color: #ffffff;
`;

export const StyledVideoImage = styled.img`
  inline-size: 100%;
`;

export const StyledVideoEntityLink = styled(EntityLink)`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-items: stretch;

  color: #000000;
`;

export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid: "author-links author-images" / minmax(0, 1fr) 40px;
  gap: 12px;

  margin-block-end: 12px;
  ${theme.breakpoints.up("tablet")} {
    grid:
      "author-images"
      "author-links"
      / minmax(0, 1fr);
  }

  ${theme.breakpoints.up("desktop")} {
    grid: "author-links author-images" / minmax(0, 1fr) 40px;
  }
`;

export const Content = styled.div``;
export const AuthorLinks = styled.div`
  grid-area: author-links;

  margin-block-end: 4px;
`;
export const AuthorImages = styled.div`
  grid-area: author-images;

  ${StyledImage} {
    background-color: transparent;
  }

  ${Figure}:before {
    inset: -2px;

    border-style: solid;
    border-width: 3px;
  }
`;

export const Headline = styled.div`
  font-weight: 700;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;

export const ActionBar = styled.div`
  time {
    color: #999999;
    font-size: 12px;
    font-family: ${fontRoboto};
  }
`;
