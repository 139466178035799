/**
 * @generated SignedSource<<8556ea6b1e79894d00fcef39ccc957fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetLabelContent$data = {
  readonly __typename: "Article";
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetLabelContent";
} | {
  readonly __typename: "Gallery";
  readonly __typename: "Gallery";
  readonly " $fragmentType": "helpersGetLabelContent";
} | {
  readonly __typename: "Video";
  readonly __typename: "Video";
  readonly " $fragmentType": "helpersGetLabelContent";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "helpersGetLabelContent";
};
export type helpersGetLabelContent$key = {
  readonly " $data"?: helpersGetLabelContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetLabelContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetLabelContent"
};

(node as any).hash = "f3cbf6d620a48012f277bc0493ead1b6";

export default node;
