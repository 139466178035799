import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { ContentItemComment } from "scmp-app/components/content/content-item-render/variants/comment";
import { ContentItemHarry } from "scmp-app/components/content/content-item-render/variants/harry";
import { HomeFollowButton } from "scmp-app/components/home/follow-button";
import {
  StyledDesktopEntityFollowButton,
  StyledMobileEntityFollowButton,
} from "scmp-app/components/home/follow-button/styles";

export const StyledContentItemComment = styled(ContentItemComment)`
  flex: 1;
`;

export const StyledContentItemHarry = styled(ContentItemHarry)`
  flex: 1;
`;

export const Title = styled(BaseLink)`
  margin-block-end: 20px;

  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledHomeFollowButton = styled(HomeFollowButton)`
  ${StyledMobileEntityFollowButton} {
    display: block;
  }
  ${StyledDesktopEntityFollowButton} {
    display: none;
  }
`;

export const CommentItems = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up("desktop")} {
    grid-template-columns: 1fr;
  }
`;

export const Container = styled.div`
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid #00000033;

  ${StyledContentItemComment}:not(:last-child), ${StyledContentItemHarry}:not(:last-child) {
    border-block-end: 1px solid #0000001a;

    padding-block-end: 20px;
    ${theme.breakpoints.up("tablet")} {
      border-block-end: 0;

      padding-block-end: 0;
    }
    ${theme.breakpoints.up("desktop")} {
      padding-block-end: 20px;
      border-block-end: 1px solid #0000001a;
    }
  }
`;

const RowStyle = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;
  }
  ${theme.breakpoints.up("desktop")} {
    flex-direction: column;
  }
`;

export const FirstRow = styled.div`
  ${RowStyle};
`;

export const SecondRow = styled.div`
  ${RowStyle};
  border-block-start: 1px solid rgba(0, 0, 0, 0.1);

  margin-block-start: 20px;
  padding-block-start: 20px;
`;

export const MoreLinkContainer = styled.div`
  margin-block-start: 20px;
  border-block-start: 1px solid #0000001a;
`;

export const MoreLink = styled(BaseLink)`
  margin-block-start: 20px;
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  font-size: 14px;

  border: 1px solid #4585ff;
  border-radius: 2px;
`;
