import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { formatInTimeZone } from "shared/lib/utils";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { harryContentItemContent$key } from "scmp-app/queries/__generated__/harryContentItemContent.graphql";

import {
  Container,
  CoverImage,
  DisplayDate,
  Headline,
  StyledCoverEntityLink,
  StyledEntityLink,
} from "./styles";

export type Props = ContentItemRenderVariantProps & {
  reference: harryContentItemContent$key;
};

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment harryContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(withCoverImage: true, withCoverImageSize660w: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    const dateTimeFormat = "d MMM yyyy";

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <Headline>{"Harry’s View | "}</Headline>
          <DisplayDate>{formatInTimeZone(new Date(), dateTimeFormat)}</DisplayDate>
        </StyledEntityLink>

        <StyledCoverEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size660w",
                mobileUp: "size660w",
                tabletUp: "size660w",
              },
            })}
          </CoverImage>
        </StyledCoverEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeHarry";

export const ContentItemHarry = withHighlightedHeadline(Component);
